import "~/sisax.css"

import { startApp, removeModulePrefix } from "~/startApp"
import Layout from '~/components/sisax/Layout.vue'
import i18n from '~/utils/i18n'

const defaultModules = removeModulePrefix(import.meta.glob(`../pages/default/**/*.vue`))
const siteModules = removeModulePrefix(import.meta.glob(`../pages/sisax/**/*.vue`))

const modules = {
  ...defaultModules,
  ...siteModules,
}
startApp(modules, Layout, i18n)


